import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';

import Login from './pages/Login';
import Signup from './pages/Signup';
import Forgot from './pages/Forgot';

import Dashboard from './pages/admin/Dashboard';
import Education from './pages/admin/Education';
import Experience from './pages/admin/Experience';
import Services from './pages/admin/Services';
import ProjectsCategory from './pages/admin/ProjectsCategory';
import Projects from './pages/admin/Projects';
import ContactUs from './pages/admin/ContactUs';

import Home from './pages/Home';
import BlogSingle from './pages/BlogSingle';

import Error404 from './pages/Error404';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/forgot-password" component={Forgot} />

                    <Route exact path="/admin/" component={Dashboard} />
                    <Route exact path="/admin/dashboard" component={Dashboard} />
                    <Route exact path="/admin/education" component={Education} />
                    <Route exact path="/admin/experience" component={Experience} />
                    <Route exact path="/admin/services" component={Services} />
                    <Route exact path="/admin/project-category" component={ProjectsCategory} />
                    <Route exact path="/admin/projects" component={Projects} />
                    <Route exact path="/admin/contactus" component={ContactUs} />

                    <Route exact path="/profile/:id" component={Home} />

                    <Route path="/blog-single" component={BlogSingle} />

                    <Route component={Error404} />

                </Switch>
            </React.Fragment>
        );
    }
}
 
export default Main;