import React, { Component } from 'react';
class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_testimonial_setions prt_toppadder80 prt_bottompadder80 page_scroll" data-scroll="2" id="testi_sec">
                    <div className="testimonial_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >Testimonial</h2>
                                        </div>
                                        <h1 className="port_heading">words from clients</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_test_data">
                                        <div className="swiper-container">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="port_testimonial_box">
                                                        <div className="testimonial_top_box">
                                                            <div className="testimonial_img">
                                                            <img src={process.env.PUBLIC_URL + '/assets/images/port_testimonial01.jpg'} alt="" className="img-fluid"/>
                                                            </div>
                                                            <div className="testimonial_icon">
                                                                <a className="video" rel="external" href="https://www.youtube.com/embed/fpQcEiwxzQE" title="Play"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191.255 191.255"> <path d="M162.929,66.612c-2.814-1.754-6.514-0.896-8.267,1.917s-0.895,6.513,1.917,8.266c6.544,4.081,10.45,11.121,10.45,18.833 s-3.906,14.752-10.45,18.833l-98.417,61.365c-6.943,4.329-15.359,4.542-22.512,0.573c-7.154-3.97-11.425-11.225-11.425-19.406 V34.262c0-8.181,4.271-15.436,11.425-19.406c7.153-3.969,15.569-3.756,22.512,0.573l57.292,35.723 c2.813,1.752,6.513,0.895,8.267-1.917c1.753-2.812,0.895-6.513-1.917-8.266L64.512,5.247c-10.696-6.669-23.661-7-34.685-0.883 C18.806,10.48,12.226,21.657,12.226,34.262v122.73c0,12.605,6.58,23.782,17.602,29.898c5.25,2.913,10.939,4.364,16.616,4.364 c6.241,0,12.467-1.754,18.068-5.247l98.417-61.365c10.082-6.287,16.101-17.133,16.101-29.015S173.011,72.899,162.929,66.612z"/> </svg></a>
                                                                <div className="btn-wave"></div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="testimonial_details_box">
                                                            <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                                                            <h3>Carolyn Bernadette</h3>
                                                            <h4>UI Designer</h4>
                                                            <div className="port_testi_quote">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="512px" height="512px" className=""> <g> <g> <polygon points="0,4 0,28 12,16 12,4 " data-original="#030104" className="active-path" data-old_color="#f4f4f4" fill="#f4f4f4"/> <polygon points="20,4 20,28 32,16 32,4 " data-original="#030104" className="active-path" data-old_color="#f4f4f4" fill="#f4f4f4"/> </g> </g> </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="port_testimonial_box">
                                                        <div className="testimonial_top_box">
                                                            <div className="testimonial_img">
                                                            <img src={process.env.PUBLIC_URL + '/assets/images/port_testimonial02.jpg'} alt="" className="img-fluid"/>
                                                            </div>
                                                            <div className="testimonial_icon">
                                                                <a className="video" rel="external" href="https://www.youtube.com/embed/fpQcEiwxzQE" title="Play"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191.255 191.255"> <path d="M162.929,66.612c-2.814-1.754-6.514-0.896-8.267,1.917s-0.895,6.513,1.917,8.266c6.544,4.081,10.45,11.121,10.45,18.833 s-3.906,14.752-10.45,18.833l-98.417,61.365c-6.943,4.329-15.359,4.542-22.512,0.573c-7.154-3.97-11.425-11.225-11.425-19.406 V34.262c0-8.181,4.271-15.436,11.425-19.406c7.153-3.969,15.569-3.756,22.512,0.573l57.292,35.723 c2.813,1.752,6.513,0.895,8.267-1.917c1.753-2.812,0.895-6.513-1.917-8.266L64.512,5.247c-10.696-6.669-23.661-7-34.685-0.883 C18.806,10.48,12.226,21.657,12.226,34.262v122.73c0,12.605,6.58,23.782,17.602,29.898c5.25,2.913,10.939,4.364,16.616,4.364 c6.241,0,12.467-1.754,18.068-5.247l98.417-61.365c10.082-6.287,16.101-17.133,16.101-29.015S173.011,72.899,162.929,66.612z"/> </svg></a>
                                                                
                                                                <div className="btn-wave"></div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="testimonial_details_box">
                                                            <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua t enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                                                            <h3>Charles David</h3>
                                                            <h4>PHP Developer</h4>
                                                            <div className="port_testi_quote">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="512px" height="512px" className=""> <g id="right_x5F_quote_x5F_alt"> <g> <polygon points="0,4 0,28 12,16 12,4 " data-original="#030104" className="active-path" data-old_color="#f4f4f4" fill="#f4f4f4"/> <polygon points="20,4 20,28 32,16 32,4 " data-original="#030104" className="active-path" data-old_color="#f4f4f4" fill="#f4f4f4"/> </g> </g> </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>	
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Testimonial;