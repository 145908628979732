import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import cookie from 'react-cookies';
import Firebase from '../../../common/FirebaseConfig';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect : '',
            uid : ''
        }
        this.LogoutAccount = this.LogoutAccount.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              that.setState({ uid : user.uid });
            }else{
                that.props.history.push('/');
            }
        });
    }
    LogoutAccount(e){
        e.preventDefault();
        Firebase.auth().signOut()
        cookie.remove('accesstoken', { path: '/' });
        this.setState({redirect: true});
    }
    render() {
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        } 
        return ( 
            <React.Fragment>
                <div className="ad_sidebar_wrapper">
                    <Link className="ad_logo" to="/admin/dashboard">Resume</Link>
                    <div className="ad_nav">
                        <ul>
                            <li><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li><Link to="/admin/education">Education</Link></li>
                            <li><Link to="/admin/experience">Experience</Link></li>
                            <li><Link to="/admin/services">Services</Link></li>
                            <li><Link to="/admin/project-category">Projects Category</Link></li>
                            <li><Link to="/admin/projects">Projects</Link></li>
                            <li><Link to="/admin/contactus">Contact Us</Link></li>
                            {/* <li><Link to="/admin/testimonial">Testimonial</Link></li>
                            <li><Link to="/admin/blog">Blog</Link></li>
                            <li><Link to="/admin/clients">Clients</Link></li> */}
                            <li><button onClick={this.LogoutAccount}>Logout</button></li>
                        </ul>
                    </div>
                    <Button style={{ marginLeft : 30, marginTop: 30 , color: '#ffffff' }} href={'/profile/'+ this.state.uid} target="_blank" variant="contained" color="primary">View Profile</Button>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Sidebar;