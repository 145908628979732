import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Firebase from './FirebaseConfig';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            banner_image : process.env.PUBLIC_URL + '/assets/images/user_image_placeholder.png',
            social_facebook : '',
            social_linkedin : '',
            social_whatsapp : '',
            social_twitter : '',
            social_instagram : ''
        }
    }
    componentDidMount(){     
        const that = this;     
        const userDetailRef = Firebase.database().ref(this.props.uid + '/userdetail');
        userDetailRef.on('value', (snapshot) => {
            let items = snapshot.val();       
            let newState = [];
            for (let item in items) {                
                newState.push({
                    id: item,
                    banner_image : items[item].banner_image,
                    social_facebook : items[item].social_facebook,
                    social_linkedin : items[item].social_linkedin,
                    social_whatsapp : items[item].social_whatsapp,
                    social_twitter : items[item].social_twitter,
                    social_instagram : items[item].social_instagram
                });
            }
            that.setState({
                banner_image : newState[0].banner_image === '' ? that.state.banner_image : newState[0].banner_image,
                social_facebook : newState[0].social_facebook,
                social_linkedin : newState[0].social_linkedin,
                social_whatsapp : newState[0].social_whatsapp,
                social_twitter : newState[0].social_twitter,
                social_instagram : newState[0].social_instagram
            });            
        });
        
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_sidebar_wrapper mport_sidebar_wrapper">
                    <div className="port_sidebar_profile">
                        <Link to="/" className="port_sidebar_position active">
                            <div className="profile_circle ">
                                <img src={this.state.banner_image} className="img-fluid" alt="" />
                            </div>
                            <p>Login</p>
                        </Link>
                    </div>
                    <div className="port_sidebar_nav">
                        <div className="port_navigation index_navigation">
                            <ul className="nav_list">
                                <li data-number="0">
                                    <div className="tooltip_box">
                                        <a href="#" className="siderbar_menuicon">
                                            <span className="first_micon">
                                                <svg className="nav_about_svg" xmlns="http://www.w3.org/2000/svg" width="26px" height="26px"> <defs> <filter id="Filter_0"> <feFlood floodColor="rgb(255, 74, 74)" floodOpacity="1" result="floodOut"/> <feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut"/> <feBlend mode="normal" in="compOut" in2="SourceGraphic"/> </filter> </defs> <g filter="url(#Filter_0)"> <path fillRule="evenodd" fill="rgb(14, 15, 33)" d="M21.937,13.863 L4.063,13.863 L4.063,7.776 L21.937,7.776 L21.937,13.863 ZM6.094,11.832 L19.906,11.832 L19.906,9.807 L6.094,9.807 L6.094,11.832 ZM21.937,3.866 L4.063,3.866 L4.063,5.897 L21.937,5.897 L21.937,3.866 ZM21.937,17.767 L12.188,17.767 L12.188,19.799 L21.937,19.799 L21.937,17.767 ZM10.156,21.830 L4.063,21.830 L4.063,15.742 L10.156,15.742 L10.156,21.830 ZM6.094,19.799 L8.125,19.799 L8.125,17.773 L6.094,17.773 L6.094,19.799 ZM22.953,26.000 L3.047,26.000 C1.367,26.000 0.000,24.633 0.000,22.953 L0.000,3.047 C0.000,1.367 1.367,0.000 3.047,0.000 L22.953,0.000 C24.633,0.000 26.000,1.367 26.000,3.047 L26.000,22.953 C26.000,24.633 24.633,26.000 22.953,26.000 L22.953,26.000 ZM3.047,2.031 C2.487,2.031 2.031,2.487 2.031,3.047 L2.031,22.953 C2.031,23.513 2.487,23.969 3.047,23.969 L22.953,23.969 C23.513,23.969 23.969,23.513 23.969,22.953 L23.969,3.047 C23.969,2.487 23.513,2.031 22.953,2.031 L3.047,2.031 Z"/> </g> </svg>
                                            </span>
                                            <span className="second_micon">
                                                <svg className="nav_about_svg" xmlns="http://www.w3.org/2000/svg" width="26px" height="26px"> <defs> <filter> <feFlood floodColor="rgb(255, 74, 74)" floodOpacity="1" result="floodOut"/> <feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut"/> <feBlend mode="normal" in="compOut" in2="SourceGraphic"/> </filter> </defs> <g filter="url(#Filter_0)"> <path fillRule="evenodd" fill="rgb(14, 15, 33)" d="M21.937,13.863 L4.063,13.863 L4.063,7.776 L21.937,7.776 L21.937,13.863 ZM6.094,11.832 L19.906,11.832 L19.906,9.807 L6.094,9.807 L6.094,11.832 ZM21.937,3.866 L4.063,3.866 L4.063,5.897 L21.937,5.897 L21.937,3.866 ZM21.937,17.767 L12.188,17.767 L12.188,19.799 L21.937,19.799 L21.937,17.767 ZM10.156,21.830 L4.063,21.830 L4.063,15.742 L10.156,15.742 L10.156,21.830 ZM6.094,19.799 L8.125,19.799 L8.125,17.773 L6.094,17.773 L6.094,19.799 ZM22.953,26.000 L3.047,26.000 C1.367,26.000 0.000,24.633 0.000,22.953 L0.000,3.047 C0.000,1.367 1.367,0.000 3.047,0.000 L22.953,0.000 C24.633,0.000 26.000,1.367 26.000,3.047 L26.000,22.953 C26.000,24.633 24.633,26.000 22.953,26.000 L22.953,26.000 ZM3.047,2.031 C2.487,2.031 2.031,2.487 2.031,3.047 L2.031,22.953 C2.031,23.513 2.487,23.969 3.047,23.969 L22.953,23.969 C23.513,23.969 23.969,23.513 23.969,22.953 L23.969,3.047 C23.969,2.487 23.513,2.031 22.953,2.031 L3.047,2.031 Z"/> </g> </svg>									
                                            </span>
                                        </a>
                                        <span className="menu_tooltip">About</span>
                                    </div>
                                </li>
                                <li data-number="1">
                                    <div className="tooltip_box">
                                        <a href="" className="siderbar_menuicon">
                                            <span className="first_micon">
                                                <svg className="nav__portfolio_svg" xmlns="http://www.w3.org/2000/svg" width="26px" height="25px"><image x="0px" y="0px" width="26px" height="25px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAMAAAAYAM5SAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABy1BMVEUbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeT///9q9kCWAAAAl3RSTlMAQFNRRAN//v2SDvO5KhkcGiOn/CVLT163vrhxjO4R5u/BxcDrCeiVvV1K+zKh1CxWVFJl9pZ0UKqNbFrt+vf4+fFu58tNTky/9XxqE2bTPS/gOwu19OlzAqRpYhfC6pCUuivKzrHWMEfQem3yxuQ4V4kiX1xbWKzeGHaIQTx+8GshMwEehjouvH2zcJiFNa0bH8yde4EEd8zwsQAAAAFiS0dEmHbRBj4AAAAHdElNRQfjBRAQDhhVNA3LAAABoklEQVQoz3WRazsbQRSAT4LN1DUyugnVNq7Fkg1CtemsBNlI0YtbUYJN742gTetatE3VXWja83e7Vhbr4X3mw3nn/XKeGYA0JnOGhjkzC4xwFiQaiDeMJTsnNy+/QMVaaKNFhnQTebujWKXklsliN6TS23fuOsvKyysqnVXV9wypuMZKa0+GOqG+waXfiu7GpmaPrYW23ldp9rQ9wIdNje56AO8jIjBKJcLamSCwdh+RGGUC8XdAZxcf4OWgHAjKvKyeYEDWpu4Q4GO4kh4Edp5664LWM+kj4H/yNC3PXAJ93p+WgcEhGEb6YkSzUWnsZaXjtIxPYBg8k1M4bVJERYm8Anj95q0iisq79yFHDrAP8DGKGjNZYDudMDY7ZwHfPMAn8+f4l6+uCMDCYtwbj9v5JVim4FvRd2qLrn6LTeumJramS2YYcT1Dtw0CUst3/VvFno0f6fFn4heDMFpmNi8/xe8toi4frdmOORVjKahe53aGAHeh0L9nTPvSAXgRQoeJ/FxX8ugCyeNIIvmHwDJewzyMyByXSq0ZSKW4v+5//wG5n3qzco5eOwAAAABJRU5ErkJggg=="/></svg>
                                            </span>
                                            <span className="second_micon">
                                                <svg className="nav__portfolio_svg" xmlns="http://www.w3.org/2000/svg" width="26px" height="25px"><image x="0px" y="0px" width="26px" height="25px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAMAAAAYAM5SAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABy1BMVEUbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeQbyeT///9q9kCWAAAAl3RSTlMAQFNRRAN//v2SDvO5KhkcGiOn/CVLT163vrhxjO4R5u/BxcDrCeiVvV1K+zKh1CxWVFJl9pZ0UKqNbFrt+vf4+fFu58tNTky/9XxqE2bTPS/gOwu19OlzAqRpYhfC6pCUuivKzrHWMEfQem3yxuQ4V4kiX1xbWKzeGHaIQTx+8GshMwEehjouvH2zcJiFNa0bH8yde4EEd8zwsQAAAAFiS0dEmHbRBj4AAAAHdElNRQfjBRAQDhhVNA3LAAABoklEQVQoz3WRazsbQRSAT4LN1DUyugnVNq7Fkg1CtemsBNlI0YtbUYJN742gTetatE3VXWja83e7Vhbr4X3mw3nn/XKeGYA0JnOGhjkzC4xwFiQaiDeMJTsnNy+/QMVaaKNFhnQTebujWKXklsliN6TS23fuOsvKyysqnVXV9wypuMZKa0+GOqG+waXfiu7GpmaPrYW23ldp9rQ9wIdNje56AO8jIjBKJcLamSCwdh+RGGUC8XdAZxcf4OWgHAjKvKyeYEDWpu4Q4GO4kh4Edp5664LWM+kj4H/yNC3PXAJ93p+WgcEhGEb6YkSzUWnsZaXjtIxPYBg8k1M4bVJERYm8Anj95q0iisq79yFHDrAP8DGKGjNZYDudMDY7ZwHfPMAn8+f4l6+uCMDCYtwbj9v5JVim4FvRd2qLrn6LTeumJramS2YYcT1Dtw0CUst3/VvFno0f6fFn4heDMFpmNi8/xe8toi4frdmOORVjKahe53aGAHeh0L9nTPvSAXgRQoeJ/FxX8ugCyeNIIvmHwDJewzyMyByXSq0ZSKW4v+5//wG5n3qzco5eOwAAAABJRU5ErkJggg=="/></svg>									
                                            </span>
                                        </a>
                                        <span className="menu_tooltip">Portfolio</span>
                                    </div>
                                </li>
                                {/* <li data-number="2">
                                    <div className="tooltip_box">
                                        <a href="" className="siderbar_menuicon">
                                            <span className="first_micon">
                                                <svg className="nav_quotes_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508.044 508.044"> <g> <g> <path d="M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938 s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303 C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z"/> <path d="M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599 c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371 C266.175,352.426,266.169,352.475,266.169,352.536z"/> </g> </g> </svg>
                                            </span>
                                            <span className="second_micon">
                                                <svg className="nav_quotes_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508.044 508.044"> <g> <g> <path d="M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938 s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303 C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z"/> <path d="M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599 c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371 C266.175,352.426,266.169,352.475,266.169,352.536z"/> </g> </g> </svg>
                                            </span>
                                        </a>
                                        <span className="menu_tooltip">Testimonial</span>
                                    </div>
                                </li> */}
                                <li data-number="3">
                                    <div className="tooltip_box">
                                        <a href="" className="siderbar_menuicon">
                                            <span className="first_micon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.56 480.56" className="nav_contact_svg" width="20px" height="20px"> <g> <g> <path d="M365.354,317.9c-15.7-15.5-35.3-15.5-50.9,0c-11.9,11.8-23.8,23.6-35.5,35.6c-3.2,3.3-5.9,4-9.8,1.8 c-7.7-4.2-15.9-7.6-23.3-12.2c-34.5-21.7-63.4-49.6-89-81c-12.7-15.6-24-32.3-31.9-51.1c-1.6-3.8-1.3-6.3,1.8-9.4 c11.9-11.5,23.5-23.3,35.2-35.1c16.3-16.4,16.3-35.6-0.1-52.1c-9.3-9.4-18.6-18.6-27.9-28c-9.6-9.6-19.1-19.3-28.8-28.8 c-15.7-15.3-35.3-15.3-50.9,0.1c-12,11.8-23.5,23.9-35.7,35.5c-11.3,10.7-17,23.8-18.2,39.1c-1.9,24.9,4.2,48.4,12.8,71.3 c17.6,47.4,44.4,89.5,76.9,128.1c43.9,52.2,96.3,93.5,157.6,123.3c27.6,13.4,56.2,23.7,87.3,25.4c21.4,1.2,40-4.2,54.9-20.9 c10.2-11.4,21.7-21.8,32.5-32.7c16-16.2,16.1-35.8,0.2-51.8C403.554,355.9,384.454,336.9,365.354,317.9z"/> <path d="M346.254,238.2l36.9-6.3c-5.8-33.9-21.8-64.6-46.1-89c-25.7-25.7-58.2-41.9-94-46.9l-5.2,37.1 c27.7,3.9,52.9,16.4,72.8,36.3C329.454,188.2,341.754,212,346.254,238.2z"/> <path d="M403.954,77.8c-42.6-42.6-96.5-69.5-156-77.8l-5.2,37.1c51.4,7.2,98,30.5,134.8,67.2c34.9,34.9,57.8,79,66.1,127.5 l36.9-6.3C470.854,169.3,444.354,118.3,403.954,77.8z"/> </g> </g> </svg>
                                            </span>
                                            <span className="second_micon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.56 480.56" className="nav_contact_svg" width="20px" height="20px"> <g> <g> <path d="M365.354,317.9c-15.7-15.5-35.3-15.5-50.9,0c-11.9,11.8-23.8,23.6-35.5,35.6c-3.2,3.3-5.9,4-9.8,1.8 c-7.7-4.2-15.9-7.6-23.3-12.2c-34.5-21.7-63.4-49.6-89-81c-12.7-15.6-24-32.3-31.9-51.1c-1.6-3.8-1.3-6.3,1.8-9.4 c11.9-11.5,23.5-23.3,35.2-35.1c16.3-16.4,16.3-35.6-0.1-52.1c-9.3-9.4-18.6-18.6-27.9-28c-9.6-9.6-19.1-19.3-28.8-28.8 c-15.7-15.3-35.3-15.3-50.9,0.1c-12,11.8-23.5,23.9-35.7,35.5c-11.3,10.7-17,23.8-18.2,39.1c-1.9,24.9,4.2,48.4,12.8,71.3 c17.6,47.4,44.4,89.5,76.9,128.1c43.9,52.2,96.3,93.5,157.6,123.3c27.6,13.4,56.2,23.7,87.3,25.4c21.4,1.2,40-4.2,54.9-20.9 c10.2-11.4,21.7-21.8,32.5-32.7c16-16.2,16.1-35.8,0.2-51.8C403.554,355.9,384.454,336.9,365.354,317.9z"/> <path d="M346.254,238.2l36.9-6.3c-5.8-33.9-21.8-64.6-46.1-89c-25.7-25.7-58.2-41.9-94-46.9l-5.2,37.1 c27.7,3.9,52.9,16.4,72.8,36.3C329.454,188.2,341.754,212,346.254,238.2z"/> <path d="M403.954,77.8c-42.6-42.6-96.5-69.5-156-77.8l-5.2,37.1c51.4,7.2,98,30.5,134.8,67.2c34.9,34.9,57.8,79,66.1,127.5 l36.9-6.3C470.854,169.3,444.354,118.3,403.954,77.8z"/> </g> </g> </svg>
                                            </span>
                                        </a>
                                        <span className="menu_tooltip">Contact</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="port_sidebar_social">
                        <div className="port__social_box">
                            <div className="port__followus">
                                <p>Follow Us</p>
                            </div>
                            <ul className="social_list">
                                <li style={this.state.social_facebook ? {} : { display: 'none' }}>
                                    <a href={this.state.social_facebook} target="_blank" rel="noopener noreferrer" className="siderbar_icon">
                                        <span className="first_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                                        <span className="second_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                                    </a>
                                </li>
                                <li style={this.state.social_linkedin ? {} : { display: 'none' }}>
                                    <a href={this.state.social_linkedin} target="_blank" rel="noopener noreferrer" className="siderbar_icon">
                                        <span className="first_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                                        <span className="second_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                                    </a>
                                </li>
                                <li style={this.state.social_whatsapp ? {} : { display: 'none' }}>
                                    <a href={'https://wa.me/'+this.state.social_whatsapp} target="_blank" rel="noopener noreferrer" className="siderbar_icon">
                                        <span className="first_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                                        <span className="second_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                                    </a>
                                </li>
                                <li style={this.state.social_twitter ? {} : { display: 'none' }}>
                                    <a href={this.state.social_twitter} target="_blank" rel="noopener noreferrer" className="siderbar_icon">
                                        <span className="first_icon"><i className="fab fa-twitter nav_twit"></i></span>
                                        <span className="second_icon"><i className="fab fa-twitter nav_twit"></i></span>
                                    </a>
                                </li>
                                <li style={this.state.social_instagram ? {} : { display: 'none' }}>
                                    <a href={this.state.social_instagram} target="_blank" rel="noopener noreferrer" className="siderbar_icon">
                                        <span className="first_icon"><i className="fab fa-instagram nav_insta"></i></span>
                                        <span className="second_icon"><i className="fab fa-instagram nav_insta"></i></span>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>	
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Sidebar;