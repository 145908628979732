import React from 'react';
import ReactDOM from 'react-dom';
export default function AlertMsg(type, message) {
    ReactDOM.render(<p className={type}>{message}</p>, document.getElementById('alertid'))
    setTimeout(() => {
        ReactDOM.render('', document.getElementById('alertid'))    
    }, 3000);
}

//add remove script on router change
export function AddScript(scriptarr = []){
    let returnValue = [];
    for(let file of scriptarr){
        const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL + `/assets/js/${file.name}`;
        script.async = false;
        document.body.appendChild(script);
        returnValue.push({
            name: file.name,
            instance: script
        });
    }
    return returnValue;
}