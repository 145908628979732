import React, { Component } from 'react';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import DatePicker from "react-datepicker";
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';

class Experience extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title : '',
            sub_title : '',
            experience_from: '',
            experience_to: '',            
            company_name : '',
            description : '',
            editid : '',
            ExperienceList : [],

            
        }
        this.handleChange = this.handleChange.bind(this);
        this.addExperience = this.addExperience.bind(this);
        this.UpdateExperience = this.UpdateExperience.bind(this);
        this.EditBtnExperience = this.EditBtnExperience.bind(this);
        this.DeleteExperience = this.DeleteExperience.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const experienceRef = Firebase.database().ref(user.uid + '/experience');
                experienceRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            title: items[item].title,
                            sub_title: items[item].sub_title,
                            experience_from: items[item].experience_from,
                            experience_to: items[item].experience_to,
                            company_name: items[item].company_name,
                            description: items[item].description,
                        });
                    }
                    that.setState({
                        ExperienceList: newState
                    });
                });
            } else {
                that.props.history.push('/');
            }
        });
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }
    addExperience(e){
        e.preventDefault();
        let experiencedata = {
            'title' : this.state.title,
            'sub_title' : this.state.sub_title,
            'experience_from' : this.state.experience_from.toString(),
            'experience_to' : this.state.experience_to.toString(),
            'company_name' : this.state.company_name,
            'description' : this.state.description
        }
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const experienceRef = Firebase.database().ref(user.uid + '/experience');
                experienceRef.push(experiencedata);   
                that.setState({
                    title : '',
                    sub_title : '',
                    experience_from : '',
                    experience_to : '',
                    company_name : '',
                    description : ''
                })
            }
        });
    }
    EditBtnExperience(id){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/experience/${id}`);
                itemRef.on('value', (snapshot) => {
                    const a = snapshot.val();
                    that.setState({
                        title : a.title,
                        sub_title : a.title,
                        experience_from : new Date(a.experience_from),
                        experience_to : new Date(a.experience_to),
                        company_name : a.company_name,
                        description : a.description,
                        editid : id
                    })
                });
            }
        });
    }
    UpdateExperience(e){
        e.preventDefault();
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/experience/${that.state.editid}`);
                itemRef.set({
                    title : that.state.title,
                    sub_title : that.state.sub_title,
                    experience_from : that.state.experience_from.toString(),
                    experience_to : that.state.experience_to.toString(),
                    company_name : that.state.company_name,
                    description : that.state.description,
                });
                that.setState({
                    title : '',
                    sub_title : '',
                    experience_from : '',
                    experience_to : '',
                    company_name : '',
                    description : '',
                    editid : ''
                })
            }
        });
        
    }
    DeleteExperience(id){
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){ 
            Firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    const itemRef = Firebase.database().ref(user.uid + `/experience/${id}`);
                    itemRef.remove();
                }
            });
        }        
    }
    ExperienceFromPickerChange = date => {
        this.setState({
            experience_from: date
        });
        
    };
    ExperienceToPickerChange = date => {
        this.setState({
            experience_to: date
        });
    };
    render() { 
        const ExperienceFromPicker = ({ value, onClick }) => (
            <TextField 
                variant="outlined"
                fullWidth
                required
                label="Experience From"
                name="experience_from"
                autoComplete="off"
                value={value} 
                onChange={this.ExperienceFromPickerChange}
                onFocus={onClick}
            />
        );
        const ExperienceToPicker = ({ value, onClick }) => (
            <TextField 
                variant="outlined"
                fullWidth
                required
                label="Experience To"
                name="experience_to"
                autoComplete="off"
                value={value} 
                onChange={this.ExperienceToPickerChange}
                onFocus={onClick}
            />
        );
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                    <Grid item xs={12} md={10} lg={5}>
                        <h1>Experience</h1><br/>
                        <form onSubmit={ this.state.editid === '' ? this.addExperience : this.UpdateExperience }>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Title"
                                    name="title"
                                    value={this.state.title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Sub Title"
                                    name="sub_title"
                                    value={this.state.sub_title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            
                            <div className="ad_input_wrapper_list">
                                <div className="ad_input_wrapper">
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        selected={this.state.experience_from}
                                        onChange={this.ExperienceFromPickerChange}
                                        customInput={<ExperienceFromPicker />}
                                    />
                                </div>
                                <div className="ad_input_wrapper">
                                    <DatePicker
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        selected={this.state.experience_to}
                                        onChange={this.ExperienceToPickerChange}
                                        customInput={<ExperienceToPicker />}
                                    />
                                </div>
                            </div>
                            
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Company Name"
                                    name="company_name"
                                    value={this.state.company_name}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows="4"
                                    label="Description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button type="submit" variant="contained" color="primary">{this.state.editid === '' ? 'Add Experience' : 'Update'}</Button>
                        </form>
                    </Grid>
                    

                    <br/><br/><br/>
                    <ReactTable
                        data={this.state.ExperienceList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'Title',
                                accessor: 'title'
                            },
                            {
                                Header : 'Sub Title',
                                accessor: 'sub_title'
                            },
                            {
                                Header : 'Experience From',
                                accessor: 'experience_from',
                                Cell : row => (
                                    <div>
                                        {new Date(row.value).toDateString()}
                                    </div>
                                )
                            },
                            {
                                Header : 'Experience To',
                                accessor: 'experience_to',
                                Cell : row => (
                                    <div>
                                        {new Date(row.value).toDateString()}
                                    </div>
                                )
                            },
                            {
                                Header : 'Company Name',
                                accessor: 'company_name'
                            },
                            {
                                Header : 'Description',
                                accessor: 'description'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => this.EditBtnExperience(row.value)}>Edit</Button> &nbsp;
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteExperience(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>

            </React.Fragment>
        );
    }
}
 
export default Experience;