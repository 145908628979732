import React, { Component } from 'react';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title : '',
            categoryid : '',
            image : '',
            link : '',
            editid : '',
            imagefile : [],
            CatList : [],
            ProjectList : []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.addProject = this.addProject.bind(this);
        this.EditBtnProject = this.EditBtnProject.bind(this);
        this.UpdateProject = this.UpdateProject.bind(this);
        this.DeleteProject = this.DeleteProject.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                //get list of category
                const categoryRef = Firebase.database().ref(user.uid + '/projectcategory');
                categoryRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            title: items[item].title
                        });
                    }
                    that.setState({
                        CatList: newState
                    });
                });

                //get list of projects
                const projectRef = Firebase.database().ref(user.uid + '/projects');
                projectRef.on('value', (snapshot) => {
                    let items = snapshot.val();                  
                    let newState = [];
                    for (let item in items) {
                        //get category name by id
                        const catname = that.state.CatList.find(cname => cname.id === items[item].categoryid);                        
                        newState.push({
                            id: item,
                            title: items[item].title,
                            image: items[item].image,
                            link: items[item].link,
                            categoryid: catname.title
                        });
                    }
                    that.setState({
                        ProjectList: newState
                    });
                });

            } else {
                that.props.history.push('/');
            }
        });
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }
    handleImageChange(e){
        let file = e.target.files[0];
        this.setState({ imagefile : file })        
    }
    addProject(e){
        e.preventDefault();
        const that = this;
        const title = this.state.title;
        const categoryid = this.state.categoryid;
        const link = this.state.link;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/projects');

                //upload image
                let file = that.state.imagefile;
                const storageRef = Firebase.storage().ref(user.uid + '/projects');
                var d = new Date();
                var n = d.getTime();
                const mainImage = storageRef.child(''+n);
                mainImage.put(file)
                .then(res => {    
                    const imgRef = Firebase.storage().ref(res.metadata.fullPath);
                    imgRef.getDownloadURL()
                    .then(function(url) {
                        let projectdata = {
                            'title' : title,
                            'image' : url,
                            'categoryid' : categoryid,
                            'link' : link
                        }
                        educationRef.push(projectdata);
                    }).catch(function(err) {
                        console.log(err);
                    });
                });
                that.setState({
                    title : '',
                    image : '',
                    categoryid : '',
                    link : '',
                    editid : ''
                })
            }
        });
    }
    EditBtnProject(id){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/projects/${id}`);
                itemRef.on('value', (snapshot) => {
                    const a = snapshot.val();
                    that.setState({
                        title : a.title,
                        categoryid : a.categoryid,
                        link : a.link,
                        editid : id
                    })
                });
            }
        });
    }
    UpdateProject(e){
        e.preventDefault();
        const that = this;
        const title = this.state.title;
        const categoryid = this.state.categoryid;
        const link = this.state.link;
        const img = this.state.ProjectList.find(item => item.id === this.state.editid);
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/projects/${that.state.editid}`);
                
                //delete old image
                var storage = Firebase.storage();
                var httpsReference = storage.refFromURL(img.image);
                httpsReference.delete().then(function() {
                    console.log('Old image deleted successfully.');
                }).catch(function(error) {
                    console.log(error);                        
                });

                //upload new icon
                let file = that.state.imagefile;
                const storageRef = Firebase.storage().ref(user.uid + '/projects');
                var d = new Date();
                var n = d.getTime();
                const mainImage = storageRef.child(''+n);
                mainImage.put(file)
                .then(res => {    
                    const imgRef = Firebase.storage().ref(res.metadata.fullPath);
                    imgRef.getDownloadURL()
                    .then(function(url) {                
                        itemRef.set({
                            title : title,
                            image : url,
                            categoryid : categoryid,
                            link : link
                        });
                        that.setState({
                            title : '',
                            image : '',
                            categoryid : '',
                            link : '',
                            editid : ''
                        })
                    }).catch(function(err) {
                        console.log(err);
                    });
                });
            }
        });
    }
    DeleteProject(id){
        const img = this.state.ProjectList.find(item => item.id === id);
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const r = window.confirm("Do you really want to Delete?"); 
                if(r === true){ 
                    // delete image from storage
                    var storage = Firebase.storage();
                    var httpsReference = storage.refFromURL(img.image);
                    httpsReference.delete().then(function() {
                        console.log('image deleted successfully.');
                    }).catch(function(error) {
                        console.log(error);                        
                    });  

                    // remove data from database
                    const itemRef = Firebase.database().ref(user.uid + `/projects/${id}`);
                    itemRef.remove();
                }
                       
            }
        });
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                <Grid item xs={12} md={10} lg={5}>
                        <h1>Projects</h1><br/>
                        <form onSubmit={ this.state.editid === '' ? this.addProject : this.UpdateProject }>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Title"
                                    name="title"
                                    value={this.state.title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel style={{backgroundColor:'#ffffff', padding:'0 4px'}} id="demo-simple-select-label">Select Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        displayEmpty
                                        name="categoryid"
                                        value={this.state.categoryid}
                                        onChange={this.handleChange}
                                    >
                                        {this.state.CatList.map(cat =>
                                            <MenuItem key={cat.id} value={cat.id}>{cat.title}</MenuItem>
                                        )}
                                        
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Image"
                                    id="iconinput"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="file"
                                    name="image"
                                    value={this.state.image}
                                    onChange={this.handleImageChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Project Link"
                                    name="link"
                                    value={this.state.link} 
                                    onChange={this.handleChange}
                                />
                            </div>

                            <Button type="submit" variant="contained" color="primary">{this.state.editid === '' ? 'Add Category' : 'Update'}</Button>
                        </form>
                    </Grid>

                    <br/><br/><br/>
                    <ReactTable
                        data={this.state.ProjectList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'Image',
                                accessor: 'image',
                                Cell : row => (
                                    <img src={row.value} alt="" style={{ width: 40, height: 40, objectFit: 'cover' }} />
                                )
                            },
                            {
                                Header : 'Title',
                                accessor: 'title'
                            },
                            {
                                Header : 'Link',
                                accessor: 'link'
                            },
                            {
                                Header : 'Category',
                                accessor: 'categoryid'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => this.EditBtnProject(row.value)}>Edit</Button> &nbsp;
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteProject(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>
            </React.Fragment>
        );
    }
}
 
export default Projects;