import React, { Component } from 'react';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class ProjectsCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title : '',
            editid : '',
            CategoryList : []
        }

        this.handleChange = this.handleChange.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.UpdateCategory = this.UpdateCategory.bind(this);
        this.EditBtnCategory = this.EditBtnCategory.bind(this);
        this.DeleteCategory = this.DeleteCategory.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/projectcategory');
                educationRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            title: items[item].title
                        });
                    }
                    that.setState({
                        CategoryList: newState
                    });
                });
            } else {
                that.props.history.push('/');
            }
        });
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }
    addCategory(e){
        e.preventDefault();
        let categorydata = {
            'title' : this.state.title
        }
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/projectcategory');
                educationRef.push(categorydata);   
                that.setState({
                    title : '',
                    editid : ''
                })
            }
        });
    }
    EditBtnCategory(id){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/projectcategory/${id}`);
                itemRef.on('value', (snapshot) => {
                    const a = snapshot.val();
                    that.setState({
                        title : a.title,
                        editid : id
                    })
                });
            }
        });
    }
    UpdateCategory(e){
        e.preventDefault();
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/projectcategory/${that.state.editid}`);
                itemRef.set({
                    title : that.state.title
                });
                that.setState({
                    title : '',
                    editid : ''
                })
            }
        });
    }
    DeleteCategory(id){
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){ 
            Firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    const itemRef = Firebase.database().ref(user.uid + `/projectcategory/${id}`);
                    itemRef.remove();
                }
            });
        }        
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                    <Grid item xs={12} md={10} lg={5}>
                        <h1>Projects Category</h1><br/>
                        <form onSubmit={ this.state.editid === '' ? this.addCategory : this.UpdateCategory }>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Title"
                                    name="title"
                                    value={this.state.title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button type="submit" variant="contained" color="primary">{this.state.editid === '' ? 'Add Category' : 'Update'}</Button>
                        </form>
                    </Grid>

                    <br/><br/><br/>
                    <ReactTable
                        data={this.state.CategoryList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'Title',
                                accessor: 'title'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => this.EditBtnCategory(row.value)}>Edit</Button> &nbsp;
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteCategory(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>
            </React.Fragment>
        );
    }
}
 
export default ProjectsCategory;