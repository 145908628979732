import React, { Component } from 'react';
import Firebase from '../common/FirebaseConfig';
import {Redirect} from 'react-router-dom';

import Isotope from 'isotope-layout';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect : '',
            CatList : [],
            plistupdated : false,
            ProjectList : []
        }
        this.filterClick = this.filterClick.bind(this);
    }
    componentDidMount(){
        const that = this;
        const refcheck = Firebase.database().ref(this.props.uid);
        refcheck.once('value', function(snapshot) {
            if (snapshot.hasChild('/')) {
                if(snapshot.key === that.props.uid){
                    
                    //get list of category
                    const categoryRef = Firebase.database().ref(that.props.uid + '/projectcategory');
                    categoryRef.on('value', (snapshot) => {
                        let items = snapshot.val();
                        let newState = [];
                        for (let item in items) {
                            newState.push({
                                id: item,
                                title: items[item].title
                            });
                        }
                        that.setState({
                            CatList: newState,
                            plistupdated : true
                        });
                    });

                    // get list of projects 
                    const educationRef = Firebase.database().ref(that.props.uid + '/projects');
                    educationRef.on('value', (snapshot) => {
                        let items = snapshot.val();
                        let newState = [];
                        for (let item in items) {
                            //get category name by id
                            const catname = that.state.CatList.find(cname => cname.id === items[item].categoryid);
                            newState.push({
                                id: item,
                                category: catname.title,
                                title: items[item].title,
                                link: items[item].link,
                                image: items[item].image
                            });
                        }
                        that.setState({
                            ProjectList: newState
                        });
                    });
                }
            }else{
                that.setState({redirect: true});
            }
        });

        var grid = document.querySelector('.gallery_grid');
        this.iso = new Isotope( grid, {
            // options...
            itemSelector: '.grid-item',
            filter: '*'
        });
    }
    componentDidUpdate(){
        if(this.state.plistupdated === true){
            setTimeout(() => {
                this.iso.reloadItems();
                this.iso.arrange();
                setTimeout(() => {
                    this.iso.layout();
                }, 1000);
            }, 200);
        }
    }
    filterClick(text, e){
        this.iso.arrange({
            filter: text === '*' ? '*' : "."+text
        });
        let elems = document.querySelectorAll('.galleryfilter');
        [].forEach.call(elems, function(el) {
            el.classList.remove("gallery_active");
        });
        e.target.classList.add('gallery_active');
    }
    render() { 
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        }
        return ( 
            <React.Fragment>
                <div className="port_projects_setions prt_bottompadder50 prt_toppadder80 page_scroll" data-scroll="1" id="project_sec" style={ this.state.ProjectList.length === 0 ? { display : 'none', backgroundColor: '#f7fbff' } : { display : 'block', backgroundColor: '#f7fbff' } }>
                    <div className="projects_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >Quality Work</h2>
                                        </div>
                                        <h1 className="port_heading">My Projects</h1>
                                    </div>
                                </div>
                                
                                <div className="port_project_gallery text-center">
                                    <div className="gallery_nav">
                                        <ul>
                                            <li><a data-filter="*" onClick={ (e) => this.filterClick(('*'), e)} className="galleryfilter gallery_active">all</a></li>
                                            {this.state.CatList.map(cat =>
                                            <li key={cat.id}><a data-filter={'.'+ cat.title.replace(/ /g,'').toLowerCase() } onClick={ (e) => this.filterClick((cat.title.replace(/ /g,'').toLowerCase()), e)} className="galleryfilter">{cat.title}</a></li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="gallery_container">
                                        <div className="gallery_grid">
                                            
                                            {this.state.ProjectList.map(project =>
                                                <div key={project.id} className={'grid-item '+ project.category.replace(/ /g,'').toLowerCase()}>
                                                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="view">
                                                        <div className="grid_img">
                                                            <img src={project.image} alt="" />
                                                        </div>
                                                        <div className="grid-content">
                                                            <h3>{project.title}</h3>
                                                            <span>{project.category}</span>
                                                        </div>
                                                    </a>	
                                                </div>
                                            )}

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Projects;