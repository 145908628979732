import React, { Component } from 'react';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';
import AlertMsg from '../../common/helpers.js';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            editid : '',
            name : '',
            email : '',
            phone : '',
            age : '',
            address : '',
            residence : '',
            profile : '',
            skype : '',
            freelance_status : '',

            bannerimagefile : [],
            uploadcvfile : [],
            banner_image_blob : '',
            banner_image : '',
            banner_title1 : '',
            banner_title2 : '',
            textslider_title : '',
            textslider_slide1 : '',
            textslider_slide2 : '',
            textslider_slide3 : '',
            downloadcv_text : '',
            downloadcv_url : '',

            about_title : '',
            about_sub_title : '',
            about_sub_description : '',

            social_facebook : '',
            social_linkedin : '',
            social_whatsapp : '',
            social_twitter : '',
            social_instagram : '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.UpdateProfile = this.UpdateProfile.bind(this);
        this.handleBannerImageChange = this.handleBannerImageChange.bind(this);
        this.handleUploadCVChange = this.handleUploadCVChange.bind(this);
        
    }
    componentDidMount(){
        const that = this;
        let userdata = {
            name : this.state.name,
            email : this.state.email,
            phone : this.state.phone,
            age : this.state.age,
            address : this.state.address,
            residence : this.state.residence,
            profile : this.state.profile,
            skype : this.state.skype,
            freelance_status : this.state.freelance_status,
            
            banner_image : this.state.banner_image,
            banner_title1 : this.state.banner_title1,
            banner_title2 : this.state.banner_title2,
            textslider_title : this.state.textslider_title,
            textslider_slide1 : this.state.textslider_slide1,
            textslider_slide2 : this.state.textslider_slide2,
            textslider_slide3 : this.state.textslider_slide3,
            downloadcv_text : this.state.downloadcv_text,
            downloadcv_url : this.state.downloadcv_url,

            about_title : this.state.about_title,
            about_sub_title : this.state.about_sub_title,
            about_sub_description : this.state.about_sub_description,

            social_facebook : this.state.social_facebook,
            social_linkedin : this.state.social_linkedin,
            social_whatsapp : this.state.social_whatsapp,
            social_twitter : this.state.social_twitter,
            social_instagram : this.state.social_instagram
        };
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const userDetailRef = Firebase.database().ref(user.uid + '/userdetail');
                userDetailRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    console.log(items);                    
                    if(items){
                        let newState = [];
                        for (let item in items) {
                            newState.push({
                                id: item,
                                name: items[item].name,
                                email: items[item].email,
                                phone : items[item].phone,
                                age : items[item].age,
                                address : items[item].address,
                                residence : items[item].residence,
                                profile : items[item].profile,
                                skype : items[item].skype,
                                freelance_status : items[item].freelance_status,

                                banner_image : items[item].banner_image,
                                banner_title1 : items[item].banner_title1,
                                banner_title2 : items[item].banner_title2,
                                textslider_title : items[item].textslider_title,
                                textslider_slide1 : items[item].textslider_slide1,
                                textslider_slide2 : items[item].textslider_slide2,
                                textslider_slide3 : items[item].textslider_slide3,
                                downloadcv_text : items[item].downloadcv_text,
                                downloadcv_url : items[item].downloadcv_url,

                                about_title : items[item].about_title,
                                about_sub_title : items[item].about_sub_title,
                                about_sub_description : items[item].about_sub_description,

                                social_facebook : items[item].social_facebook,
                                social_linkedin : items[item].social_linkedin,
                                social_whatsapp : items[item].social_whatsapp,
                                social_twitter : items[item].social_twitter,
                                social_instagram : items[item].social_instagram
                            });
                        }
                        that.setState({
                            editid: newState[0].id,
                            name: newState[0].name,
                            email: newState[0].email,
                            phone : newState[0].phone,
                            age : newState[0].age,
                            address : newState[0].address,
                            residence : newState[0].residence,
                            profile : newState[0].profile,
                            skype : newState[0].skype,
                            freelance_status : newState[0].freelance_status,

                            banner_image : newState[0].banner_image,
                            banner_title1 : newState[0].banner_title1,
                            banner_title2 : newState[0].banner_title2,
                            textslider_title : newState[0].textslider_title,
                            textslider_slide1 : newState[0].textslider_slide1,
                            textslider_slide2 : newState[0].textslider_slide2,
                            textslider_slide3 : newState[0].textslider_slide3,
                            downloadcv_text : newState[0].downloadcv_text,
                            downloadcv_url : newState[0].downloadcv_url,

                            about_title : newState[0].about_title,
                            about_sub_title : newState[0].about_sub_title,
                            about_sub_description : newState[0].about_sub_description,

                            social_facebook : newState[0].social_facebook,
                            social_linkedin : newState[0].social_linkedin,
                            social_whatsapp : newState[0].social_whatsapp,
                            social_twitter : newState[0].social_twitter,
                            social_instagram : newState[0].social_instagram
                        });
                    }else{
                        userDetailRef.push(userdata);
                    }
                    
                });
                
            }else{
                that.props.history.push('/');
            }
        });         
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    handleBannerImageChange(e){
        let file = e.target.files[0];
        if(file){
            if(file.type.includes('png') === true || file.type.includes('jpg') === true || file.type.includes('jpeg') === true){
                
                this.setState({ bannerimagefile : file });
                // get blob file to set image locally
                let fr = new FileReader();
                fr.onloadend = (e) => {
                    this.setState({ banner_image_blob : e.target.result });
                }
                fr.readAsDataURL(file);
                
                const that = this;
                Firebase.auth().onAuthStateChanged(function(user) {
                    if (user) {
                        const itemRef = Firebase.database().ref(user.uid + `/userdetail/${that.state.editid}` + '/banner_image');
                        if(itemRef){
                            //check banner image uploaded or not
                            if(that.state.bannerimagefile.length === 0){
                                itemRef.set(that.state.banner_image);
                                AlertMsg('success', 'Profile Image uploaded.');
                            }else{
                                //delete old banner image
                                if(that.state.banner_image){                           
                                    var storage = Firebase.storage();
                                    var httpsReference = storage.refFromURL(that.state.banner_image);
                                    httpsReference.delete().then(function() {
                                        console.log('Old icon deleted successfully.');
                                    }).catch(function(error) {
                                        console.log(error);                        
                                    });
                                }

                                //upload banner image
                                let file = that.state.bannerimagefile;
                                const storageRef = Firebase.storage().ref(user.uid + '/userdetail');
                                var d = new Date();
                                var n = d.getTime();
                                var fname = file.name.split('.').pop();
                                const bannerImage = storageRef.child(''+n+'.'+fname);                 
                                bannerImage.put(file)
                                .then(res => {   
                                    const imgRef = Firebase.storage().ref(res.metadata.fullPath);
                                    imgRef.getDownloadURL()
                                    .then(function(url) {
                                        itemRef.set(url);
                                        AlertMsg('success', 'Profile Image uploaded.');
                                    }).catch(function(err) {
                                        console.log(err);
                                    });
                                });

                            }
                        }
                    }
                });

            }else{
                e.target.value = '';
                AlertMsg('error', 'You can upload only Image! (allowed format is png,jpg,jpeg)')
            }
        }
    }

    handleUploadCVChange(e){
        e.preventDefault();
        let file = e.target.files[0];
        if(file){
            console.log(file.type);            
            if(file.type.includes('application/msword') === true || file.type.includes('doc') === true || file.type.includes('docx') === true || file.type.includes('pdf') === true){
                
                this.setState({ uploadcvfile : file });
                console.log(file);
                console.log(file.name.split('.').pop());
                console.log(this.state.uploadcvfile);
                

                const that = this;
                Firebase.auth().onAuthStateChanged(function(user) {
                    if (user) {
                        const itemRef1 = Firebase.database().ref(user.uid + `/userdetail/${that.state.editid}` + '/downloadcv_url');
                        if(itemRef1){
                            //check CV uploaded or not
                            if(that.state.uploadcvfile.length === 0){
                                itemRef1.set(that.state.downloadcv_url);
                                AlertMsg('success', 'CV uploaded.');
                            }else{

                                //delete old CV
                                if(that.state.downloadcv_url){                           
                                    var storage = Firebase.storage();
                                    var httpsReference = storage.refFromURL(that.state.downloadcv_url);
                                    httpsReference.delete().then(function() {
                                        console.log('Old CV deleted successfully.');
                                    }).catch(function(error) {
                                        console.log(error);                        
                                    });
                                }

                                //upload banner image
                                let file = that.state.uploadcvfile;
                                const storageRef = Firebase.storage().ref(user.uid + '/userdetail');
                                var d = new Date();
                                var n = d.getTime();
                                var fname = file.name.split('.').pop();
                                const uploadCV = storageRef.child(''+n+'.'+fname);                 
                                uploadCV.put(file)
                                .then(res => {   
                                    const cvRef = Firebase.storage().ref(res.metadata.fullPath);
                                    cvRef.getDownloadURL()
                                    .then(function(url) {
                                        itemRef1.set(url);
                                        AlertMsg('success', 'CV uploaded.');
                                    }).catch(function(err) {
                                        console.log(err);
                                    });
                                });

                            }
                        }
                    }
                });


            }else{
                e.target.value = '';
                AlertMsg('error', 'You can upload only DOC and PDF File.')
            }
        }
    }

    UpdateProfile(e){
        e.preventDefault();
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/userdetail/${that.state.editid}`);
                if(itemRef){
                    //check banner image uploaded or not
                    itemRef.set({
                        name : that.state.name,
                        email : that.state.email,
                        phone : that.state.phone,
                        age : that.state.age,
                        address : that.state.address,
                        residence : that.state.residence,
                        profile : that.state.profile,
                        skype : that.state.skype,
                        freelance_status : that.state.freelance_status,
                        
                        banner_image : that.state.banner_image,
                        banner_title1 : that.state.banner_title1,
                        banner_title2 : that.state.banner_title2,
                        textslider_title : that.state.textslider_title,
                        textslider_slide1 : that.state.textslider_slide1,
                        textslider_slide2 : that.state.textslider_slide2,
                        textslider_slide3 : that.state.textslider_slide3,
                        downloadcv_text : that.state.downloadcv_text,
                        downloadcv_url : that.state.downloadcv_url,

                        about_title : that.state.about_title,
                        about_sub_title : that.state.about_sub_title,
                        about_sub_description : that.state.about_sub_description,

                        social_facebook : that.state.social_facebook,
                        social_linkedin : that.state.social_linkedin,
                        social_whatsapp : that.state.social_whatsapp,
                        social_twitter : that.state.social_twitter,
                        social_instagram : that.state.social_instagram,
                    });
                    
                    AlertMsg('success', 'Profile updated successfully.');
                }
            }
        });
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                    <h1>Profile</h1><br/>
                        <form onSubmit={ this.UpdateProfile }>
                            <h5>Basic Details</h5><br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            value={ this.state.name} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={ this.state.email} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Phone"
                                            name="phone"
                                            value={ this.state.phone} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Age"
                                            name="age"
                                            value={ this.state.age} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Address"
                                            name="address"
                                            value={ this.state.address} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Residence"
                                            name="residence"
                                            value={ this.state.residence} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Profile"
                                            name="profile"
                                            value={ this.state.profile} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Skype ID"
                                            name="skype"
                                            value={ this.state.skype} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Freelance Status"
                                            name="freelance_status"
                                            value={ this.state.freelance_status} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                            
                            </Grid>
                            <hr/>
                            <h5>Banner Details</h5><br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10} sm={10}>
                                            <div className="ad_input_wrapper">
                                                <TextField 
                                                    variant="outlined"
                                                    fullWidth
                                                    label="banner Image"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="file"
                                                    accept="image/*"
                                                    name="banner_image"
                                                    onChange={this.handleBannerImageChange}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            <img src={this.state.banner_image_blob ? this.state.banner_image_blob : this.state.banner_image} style={{width: 50, height: 50, objectFit:'cover'}} alt="" />
                                        </Grid>
                                    </Grid>
                                        
                                    
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="banner Title 1"
                                            name="banner_title1"
                                            value={this.state.banner_title1} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="banner Title 2"
                                            name="banner_title2"
                                            value={this.state.banner_title2} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Text Slider Title"
                                            name="textslider_title"
                                            value={this.state.textslider_title} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Slide Text 1"
                                            name="textslider_slide1"
                                            value={this.state.textslider_slide1} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Slide Text 2"
                                            name="textslider_slide2"
                                            value={this.state.textslider_slide2} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Slide Text 3"
                                            name="textslider_slide3"
                                            value={this.state.textslider_slide3} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Download CV Button Text"
                                            name="downloadcv_text"
                                            value={this.state.downloadcv_text} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Download CV URL"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="file" 
                                            onChange={this.handleUploadCVChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <hr/>
                            <h5>About Section</h5><br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="About Title"
                                            name="about_title"
                                            value={this.state.about_title} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="About Sub Title"
                                            name="about_sub_title"
                                            value={this.state.about_sub_title} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="About description"
                                            name="about_sub_description"
                                            value={this.state.about_sub_description} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <hr/>
                            <h5>Social url's</h5><br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Facebook"
                                            name="social_facebook"
                                            value={this.state.social_facebook} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Linkedin"
                                            name="social_linkedin"
                                            value={this.state.social_linkedin} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Whatsapp Number"
                                            name="social_whatsapp"
                                            value={this.state.social_whatsapp} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Twitter"
                                            name="social_twitter"
                                            value={this.state.social_twitter} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="ad_input_wrapper">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            label="Instagram"
                                            name="social_instagram"
                                            value={this.state.social_instagram} 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Button type="submit" variant="contained" color="primary">Update</Button>
                        </form>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Dashboard;