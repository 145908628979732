import React, { Component } from 'react';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';


import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


class Education extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title : '',
            location : '',
            year : '',
            description : '',
            editid : '',
            EducationList : []
        }
        this.handleChange = this.handleChange.bind(this);
        this.addEducation = this.addEducation.bind(this);
        this.UpdateEducation = this.UpdateEducation.bind(this);
        this.EditBtnEducation = this.EditBtnEducation.bind(this);
        this.DeleteEducation = this.DeleteEducation.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/education');
                educationRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            title: items[item].title,
                            location: items[item].location,
                            year: items[item].year,
                            description: items[item].description,
                        });
                    }
                    that.setState({
                        EducationList: newState
                    });
                });
            } else {
                that.props.history.push('/');
            }
        });
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }
    addEducation(e){
        e.preventDefault();
        let educationdata = {
            'title' : this.state.title,
            'location' : this.state.location,
            'year' : this.state.year,
            'description' : this.state.description
        }
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/education');
                educationRef.push(educationdata);   
                that.setState({
                    title : '',
                    description : '',
                    location : '',
                    year : '',
                    editid : ''
                })
            }
        });
    }
    EditBtnEducation(id){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/education/${id}`);
                itemRef.on('value', (snapshot) => {
                    const a = snapshot.val();
                    that.setState({
                        title : a.title,
                        description : a.description,
                        location : a.location,
                        year : a.year,
                        editid : id
                    })
                });
            }
        });
    }
    UpdateEducation(e){
        e.preventDefault();
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/education/${that.state.editid}`);
                itemRef.set({
                    title : that.state.title,
                    description : that.state.description,
                    location : that.state.location,
                    year : that.state.year,
                });
                that.setState({
                    title : '',
                    description : '',
                    location : '',
                    year : '',
                    editid : ''
                })
            }
        });
        
    }
    DeleteEducation(id){
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){ 
            Firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    const itemRef = Firebase.database().ref(user.uid + `/education/${id}`);
                    itemRef.remove();
                }
            });
        }        
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                    <Grid item xs={12} md={10} lg={5}>
                        <h1>Education</h1><br/>
                        <form onSubmit={ this.state.editid === '' ? this.addEducation : this.UpdateEducation }>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Title"
                                    name="title"
                                    value={this.state.title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Location"
                                    name="location"
                                    value={this.state.location}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Year"
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows="4"
                                    label="Description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button type="submit" variant="contained" color="primary">{this.state.editid === '' ? 'Add Education' : 'Update'}</Button>
                        </form>
                    </Grid>
                    

                    <br/><br/><br/>
                    <ReactTable
                        data={this.state.EducationList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'Title',
                                accessor: 'title'
                            },
                            {
                                Header : 'Description',
                                accessor: 'description'
                            },
                            {
                                Header : 'Location',
                                accessor: 'location'
                            },
                            {
                                Header : 'Year',
                                accessor: 'year'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => this.EditBtnEducation(row.value)}>Edit</Button> &nbsp;
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteEducation(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>

            </React.Fragment>
        );
    }
}
 
export default Education;