import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div class="vd_error_page">
                <img src={process.env.PUBLIC_URL + '/assets/images/error404.gif'} alt="" />
                    <h1>Page not found!</h1>
                <Link to="/" class="portfolio_btn btn_red">
                    <span class="first_text">Back to Home</span>
                    <span class="second_text">Back to Home</span>
                </Link>
            </div>
        );
    }
}
 
export default Error404;