import React, { Component } from 'react';
import Main from './components/Main';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <React.Fragment>
        <div id="alertid" className="alert_wrapper"></div>
        <Main />
        <div className="bottom_top">
          <i className="fas fa-arrow-up">
          </i><i className="fas fa-arrow-up"></i>
        </div>
      </React.Fragment>
    );
  }
}
 
export default App;