import React, { Component } from 'react';

import Sidebar from '../common/Sidebar';
import Banner from '../sections/Banner';
import About from '../sections/About';
import Education from '../sections/Education';
import Progress from '../sections/Progress';
import Experience from '../sections/Experience';
import Services from '../sections/Services';
import Projects from '../sections/Projects';
import Testimonial from '../sections/Testimonial';
import BlogList from '../sections/BlogList';
import Clients from '../sections/Clients';
import ContactUs from '../sections/ContactUs';

import {AddScript} from '../common/helpers.js';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount(){
        //add script
        this.jsfiles= [{ name : 'typed.min.js' }, { name : 'cvtext1.js' }, { name : 'cvtext2.js' }, { name : 'isotope.pkgd.min.js' }, { name : 'jquery.magnific-popup.min.js' }, { name : 'swiper.min.js' }, { name : 'circle-progress.js' }, { name : 'jquery-jvectormap.min.js' }, { name : 'jquery-jvectormap-world-mill.js' }, { name : 'scrollbar.js' }, { name : 'custom.js' }]
        this.jsfiles = AddScript(this.jsfiles);
    }
    componentWillUnmount(){
        //remove script
        for(let jsfile of this.jsfiles){
            document.body.removeChild(jsfile.instance);
        }
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar uid = {this.props.match.params.id} />
                <Banner uid = {this.props.match.params.id}/>
                <div className="port_sec_warapper">
                    <About uid = {this.props.match.params.id} />
                    <Education uid = {this.props.match.params.id} />
                    <Services uid = {this.props.match.params.id} />
                    {/* <Progress uid = {this.props.match.params.id} /> */}
                    <Experience uid = {this.props.match.params.id} />
                    <Projects uid = {this.props.match.params.id} />
                    {/* <Testimonial uid = {this.props.match.params.id} />
                    <BlogList uid = {this.props.match.params.id} />
                    <Clients uid = {this.props.match.params.id} /> */}
                    <ContactUs uid = {this.props.match.params.id} />
                </div>
            </React.Fragment>
        );
    }
}
 
export default Home;