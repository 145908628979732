import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyAMmIyUIJpHALqsjuVtQuUSOIbVnlXHvOM",
    authDomain: "resume-f99ab.firebaseapp.com",
    databaseURL: "https://resume-f99ab.firebaseio.com",
    projectId: "resume-f99ab",
    storageBucket: "resume-f99ab.appspot.com",
    messagingSenderId: "48554822101",
    appId: "1:48554822101:web:b2065c5e17291d485b955c"
};

export default firebase.initializeApp(config);