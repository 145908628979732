import React, { Component } from 'react';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            first_name : '',
            last_name : '',
            contemail : '',
            subject : '',
            message : '',
            ContactUSList : [],
        }
        this.DeleteExperience = this.DeleteExperience.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const experienceRef = Firebase.database().ref(user.uid + '/contactdata');
                experienceRef.on('value', (snapshot) => {
                    let items = snapshot.val();               
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            first_name: items[item].first_name,
                            last_name: items[item].last_name,
                            contemail: items[item].email,
                            subject: items[item].subject,
                            message: items[item].message,
                            date: items[item].date,
                        });
                    }
                    that.setState({
                        ContactUSList: newState
                    });
                });
            } else {
                that.props.history.push('/');
            }
        });
    }
    DeleteExperience(id){
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){ 
            Firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    const itemRef = Firebase.database().ref(user.uid + `/contactdata/${id}`);
                    itemRef.remove();
                }
            });
        }        
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                    <Grid item xs={12} md={10} lg={5}>
                        <h1>Contact Us</h1><br/>
                    </Grid>
                    <ReactTable
                        data={this.state.ContactUSList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'First Name',
                                accessor: 'first_name'
                            },
                            {
                                Header : 'Last Name',
                                accessor: 'last_name'
                            },
                            {
                                Header : 'Email',
                                accessor: 'contemail'
                            },
                            {
                                Header : 'Date',
                                accessor: 'date',
                                Cell : row => (
                                    <div>
                                        {new Date(row.value).toDateString() + ' - ' + new Date(row.value).toLocaleTimeString()}
                                    </div>
                                )
                            },
                            {
                                Header : 'Subject',
                                accessor: 'subject'
                            },
                            {
                                Header : 'Message',
                                accessor: 'message'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteExperience(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>

            </React.Fragment>
        );
    }
}
 
export default ContactUs;