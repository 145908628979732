import React, { Component } from 'react';
import Firebase from '../common/FirebaseConfig';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name : 'David Parker',
            email : '',
            phone : '',
            age : '',
            address : '',
            residence : '',
            profile : 'UI & UX Designer',
            skype : '',
            freelance_status : '',
            
            banner_image : process.env.PUBLIC_URL + '/assets/images/user_image_placeholder.png',
            about_title : "I'm In The Design Industry With 10 Years Of Experience.",
            about_sub_title : 'About Me',
            about_sub_description : "There are many variations of passages of Lorem Ipsum , but the a have suffered are some form, by injected humour, or the words which don't look even slightl. If you use a passage of, you need to be sure there isn't anything look even slightly believable. If you are going to use a of , you need to be sure there isn't hidden in the middle the on the tend. Embarrassing hidden in the middle of text. All the Lorem Ipsum generate on the are Internet tend to repeat predefined chunks as necessary, making of this the first true generator on the Internet which don't look even you.",
            downloadcv_text : 'Download CV',
            downloadcv_url : '',
            social_facebook : '',
            social_linkedin : '',
            social_whatsapp : '',
            social_twitter : '',
            social_instagram : ''
        }
    }
    componentDidMount(){     
        const that = this;     
        const userDetailRef = Firebase.database().ref(this.props.uid + '/userdetail');
        userDetailRef.on('value', (snapshot) => {
            let items = snapshot.val();        
            let newState = [];
            for (let item in items) {                
                newState.push({
                    id: item,
                    name: items[item].name,
                    email: items[item].email,
                    phone : items[item].phone,
                    age : items[item].age,
                    address : items[item].address,
                    residence : items[item].residence,
                    profile : items[item].profile,
                    skype : items[item].skype,
                    freelance_status : items[item].freelance_status,
                    banner_image : items[item].banner_image,
                    about_title : items[item].about_title,
                    about_sub_title : items[item].about_sub_title,
                    about_sub_description : items[item].about_sub_description,
                    downloadcv_text : items[item].downloadcv_text,
                    downloadcv_url : items[item].downloadcv_url,
                    social_facebook : items[item].social_facebook,
                    social_linkedin : items[item].social_linkedin,
                    social_whatsapp : items[item].social_whatsapp,
                    social_twitter : items[item].social_twitter,
                    social_instagram : items[item].social_instagram
                });
            }
            that.setState({
                name: newState[0].name === '' ? that.state.name : newState[0].name,
                email: newState[0].email,
                phone : newState[0].phone,
                age : newState[0].age,
                address : newState[0].address,
                residence : newState[0].residence,
                profile : newState[0].profile === '' ? that.state.profile : newState[0].profile,
                skype : newState[0].skype,
                freelance_status : newState[0].freelance_status,
                banner_image : newState[0].banner_image === '' ? that.state.banner_image : newState[0].banner_image,
                about_title : newState[0].about_title === '' ? that.state.about_title : newState[0].about_title,
                about_sub_title : newState[0].about_sub_title === '' ? that.state.about_sub_title : newState[0].about_sub_title,
                about_sub_description : newState[0].about_sub_description === '' ? that.state.about_sub_description : newState[0].about_sub_description,
                downloadcv_text : newState[0].downloadcv_text === '' ? that.state.downloadcv_text : newState[0].downloadcv_text,
                downloadcv_url : newState[0].downloadcv_url,
                social_facebook : newState[0].social_facebook,
                social_linkedin : newState[0].social_linkedin,
                social_whatsapp : newState[0].social_whatsapp,
                social_twitter : newState[0].social_twitter,
                social_instagram : newState[0].social_instagram
            });  
            document.title = that.state.name + ' - ' + that.state.profile;
        });
        
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_about_setions prt_toppadder80 page_scroll" data-scroll="0" id="about_sec">
                    <div className="selfintro_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-5 col-lg-12 col-md-12">
                                    <div className="about_leftsection ">
                                        <div className="img_warapper">
                                            <img className="img-fluid" src={this.state.banner_image} alt="About" title="more information click + icon" />
                                            <div className="icon" title="more information click + icon">
                                                <div className="iconbox">
                                                    <i className="fa fa-plus icon_plus" aria-hidden="true"></i>
                                                    <i className="fa fa-minus icon_minus" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left_deatils">
                                            <div className="personal_details">
                                                <h1 className="ps_name">{this.state.name}</h1>
                                                <p className="ps_designation">{this.state.profile}</p>
                                                <ul className="info-list">
                                                <li><span className="title">Age</span><span className="value">{this.state.age ? this.state.age : '-'}</span></li>
                                                
                                                <li><span className="title">Address</span><span className="value">{this.state.address ? this.state.address : '-'}</span></li>
                                                <li><span className="title">E-mail</span><span className="value"><a href={'mailto:'+ this.state.email}>{this.state.email ? this.state.email : '-'}</a></span></li>
                                                <li><span className="title">Residence</span><span className="value">{this.state.residence ? this.state.residence : '-'}</span></li>
                                                <li><span className="title">Phone</span><span className="value">{this.state.phone ? this.state.phone : '-'}</span></li>
                                                <li><span className="title">Skype</span><span className="value">{this.state.skype ? this.state.skype : '-'}</span></li>
                                                <li><span className="title">Freelance</span><span className="value available">{this.state.freelance_status ? this.state.freelance_status : '-'}</span></li>
                                                </ul>
                                                
                                                <ul className="social-links">
                                                    <li style={this.state.social_facebook ? {} : { display: 'none' }}><a className="tip social-button" href={this.state.social_facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li> 
                                                    <li style={this.state.social_linkedin ? {} : { display: 'none' }}><a className="tip social-button" href={this.state.social_linkedin} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li style={this.state.social_whatsapp ? {} : { display: 'none' }}><a className="tip social-button" href={'https://wa.me/'+this.state.social_whatsapp} target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a></li>
                                                    <li style={this.state.social_twitter ? {} : { display: 'none' }}><a className="tip social-button" href={this.state.social_twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                                                    <li style={this.state.social_instagram ? {} : { display: 'none' }}><a className="tip social-button" href={this.state.social_instagram} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-12 col-md-12">
                                    <div className="right_section">
                                        <div className="port_heading_wrapper">
                                            <div className="port_sub_heading_wrapper">
                                                <h2 className="port_sub_heading" >{this.state.about_sub_title}</h2>
                                            </div>
                                        </div>
                                        <h2 className="about_tophead">{this.state.about_title}</h2>
                                        <p className="about_deatils">{this.state.about_sub_description}</p>
                                        <div className="signature_box">
                                            <div className="name">
                                                <h2>{this.state.name}</h2>
                                                <p>{this.state.profile}</p>
                                            </div>
                                            {/* <div className="signature">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/signature.png'} alt="image" />
                                            </div> */}
                                        </div>
                                        <div className="anout_section_btn">
                                            <a href={this.state.downloadcv_url} target="_blank" rel="noopener noreferrer" className="portfolio_btn btn_yellow">
                                                <span className="first_text">{this.state.downloadcv_text}</span>
                                                <span className="second_text">{this.state.downloadcv_text}</span>
                                            </a>
                                            <button type="button" className="portfolio_btn btn_red" id="redirect_contact">
                                                <span className="first_text">Hire Me</span>
                                                <span className="second_text">Hire Me</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>	
                </div>
            </React.Fragment>
        );
    }
}
 
export default About;