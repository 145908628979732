import React, { Component } from 'react';
import Firebase from '../../common/FirebaseConfig';
import Sidebar from './common/Sidebar';

import ReactTable from 'react-table';
import '../../../../node_modules/react-table/react-table.css';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title : '',
            icon : '',
            description : '',
            iconfile : [],
            editid : '',
            ServiceList : []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleIconChange = this.handleIconChange.bind(this);
        this.addServices = this.addServices.bind(this);
        this.EditBtnService = this.EditBtnService.bind(this);
        this.UpdateServices = this.UpdateServices.bind(this);
        this.DeleteService = this.DeleteService.bind(this);
    }
    componentDidMount(){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const servicesRef = Firebase.database().ref(user.uid + '/services');
                servicesRef.on('value', (snapshot) => {
                    let items = snapshot.val();
                    let newState = [];
                    for (let item in items) {
                        newState.push({
                            id: item,
                            title: items[item].title,
                            icon: items[item].icon,
                            description: items[item].description,
                        });
                    }
                    that.setState({
                        ServiceList: newState
                    });
                });
            } else {
                that.props.history.push('/');
            }
        });
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }
    handleIconChange(e){
        let file = e.target.files[0];
        this.setState({ iconfile : file })        
    }
    addServices(e){
        e.preventDefault();
        const that = this;
        const title = this.state.title;
        const description = this.state.description;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const educationRef = Firebase.database().ref(user.uid + '/services');

                //upload icon
                let file = that.state.iconfile;
                const storageRef = Firebase.storage().ref(user.uid + '/services');
                var d = new Date();
                var n = d.getTime();
                const mainImage = storageRef.child(''+n);
                mainImage.put(file)
                .then(res => {    
                    const imgRef = Firebase.storage().ref(res.metadata.fullPath);
                    imgRef.getDownloadURL()
                    .then(function(url) {
                        let servicesdata = {
                            'title' : title,
                            'icon' : url,
                            'description' : description
                        }
                        educationRef.push(servicesdata);
                    }).catch(function(err) {
                        console.log(err);
                    });
                });
                that.setState({
                    title : '',
                    icon : '',
                    description : '',
                    editid : ''
                })
            }
        });
    }
    EditBtnService(id){
        const that = this;
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/services/${id}`);
                itemRef.on('value', (snapshot) => {
                    const a = snapshot.val();
                    that.setState({
                        title : a.title,
                        description : a.description,
                        editid : id
                    })
                });
            }
        });
    }
    UpdateServices(e){
        e.preventDefault();
        const that = this;
        const title = this.state.title;
        const description = this.state.description;
        const img = this.state.ServiceList.find(item => item.id === this.state.editid);
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const itemRef = Firebase.database().ref(user.uid + `/services/${that.state.editid}`);
                
                //delete old icon
                var storage = Firebase.storage();
                var httpsReference = storage.refFromURL(img.icon);
                httpsReference.delete().then(function() {
                    console.log('Old icon deleted successfully.');
                }).catch(function(error) {
                    console.log(error);                        
                });

                //upload new icon
                let file = that.state.iconfile;
                const storageRef = Firebase.storage().ref(user.uid + '/services');
                var d = new Date();
                var n = d.getTime();
                const mainImage = storageRef.child(''+n);
                mainImage.put(file)
                .then(res => {    
                    const imgRef = Firebase.storage().ref(res.metadata.fullPath);
                    imgRef.getDownloadURL()
                    .then(function(url) {
                        itemRef.set({
                            title : title,
                            icon : url,
                            description : description,
                        });
                        that.setState({
                            title : '',
                            icon : '',
                            description : '',
                            editid : ''
                        })
                    }).catch(function(err) {
                        console.log(err);
                    });
                });
            }
        });
        
    }
    DeleteService(id){
        const img = this.state.ServiceList.find(item => item.id === id);
        Firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                const r = window.confirm("Do you really want to Delete?"); 
                if(r === true){ 
                    // delete image from storage
                    var storage = Firebase.storage();
                    var httpsReference = storage.refFromURL(img.icon);
                    httpsReference.delete().then(function() {
                        console.log('icon deleted successfully.');
                    }).catch(function(error) {
                        console.log(error);                        
                    });  

                    // remove data from database
                    const itemRef = Firebase.database().ref(user.uid + `/services/${id}`);
                    itemRef.remove();
                }
                       
            }
        });
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="ad_content_wrapper">
                <Grid item xs={12} md={10} lg={5}>
                        <h1>Services</h1><br/>
                        <form onSubmit={ this.state.editid === '' ? this.addServices : this.UpdateServices }>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Title"
                                    name="title"
                                    value={this.state.title} 
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label="Icon"
                                    id="iconinput"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="file"
                                    name="icon"
                                    value={this.state.icon}
                                    onChange={this.handleIconChange}
                                />
                            </div>
                            <div className="ad_input_wrapper">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    required
                                    multiline
                                    rows="4"
                                    label="Description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <Button type="submit" variant="contained" color="primary">{this.state.editid === '' ? 'Add Services' : 'Update'}</Button>
                        </form>
                    </Grid>

                    <br/><br/><br/>
                    <ReactTable
                        data={this.state.ServiceList}
                        defaultPageSize = {10}
                        columns={[
                            {
                                Header : 'Icon',
                                accessor: 'icon',
                                Cell : row => (
                                    <img src={row.value} alt="" style={{ width: 40, height: 40, objectFit: 'cover' }} />
                                )
                            },
                            {
                                Header : 'Title',
                                accessor: 'title'
                            },
                            {
                                Header : 'Description',
                                accessor: 'description'
                            },
                            {
                                Header : 'Action',
                                accessor: 'id',
                                Cell : row => (
                                    <div>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => this.EditBtnService(row.value)}>Edit</Button> &nbsp;
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => this.DeleteService(row.value)}>Delete</Button>
                                    </div>
                                )
                            },
                        ]}
                        

                    />
                </div>
            </React.Fragment>
        );
    }
}
 
export default Services;