import React, { Component } from 'react';
class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_progress_setions prt_toppadder80">
                    <div className="progress_section">
                        <div className="container">
                            <div className="port_progress_mainbox">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6 col-sm-6 text-center">
                                        <div className="progressbar">
                                        <div className="second circle" data-percent="92">
                                        <strong></strong>
                                        </div>
                                        <span>Projects </span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-6 col-sm-6 text-center">
                                        <div className="progressbar">
                                        <div className="second circle" data-percent="100">
                                        <strong></strong>
                                        </div>
                                        <span>Team Work</span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-6 col-sm-6 text-center">
                                        <div className="progressbar">
                                        <div className="second circle" data-percent="95">
                                        <strong></strong>
                                        </div>
                                        <span>Happy Clients</span>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6 text-center">
                                        <div className="progressbar">
                                        <div className="second circle" data-percent="80">
                                        <strong></strong>
                                        </div>
                                        <span>Creativity</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Progress;