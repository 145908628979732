import React, { Component } from 'react';
import Firebase from '../common/FirebaseConfig';

class Experience extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ExperienceList : []
        }
    }
    componentDidMount(){
        const that = this;
        const experienceRef = Firebase.database().ref(this.props.uid + '/experience');
        experienceRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let newState = [];
            for (let item in items) {
                newState.push({
                    id: item,
                    title: items[item].title,
                    sub_title: items[item].sub_title,
                    experience_from: items[item].experience_from,
                    experience_to: items[item].experience_to,
                    company_name: items[item].company_name,
                    description: items[item].description,
                });
            }
            that.setState({
                ExperienceList: newState
            });
        });
    }
    render() {
        let even = 0, odd = 0;
        return ( 
            <React.Fragment>
                <div className="port_experience_setions prt_toppadder80" style={ this.state.ExperienceList.length === 0 ? { display : 'none' } : { display : 'block' } }>
                    <div className="experience_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >Involvement</h2>
                                        </div>
                                        <h1 className="port_heading">My Experience</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.ExperienceList.map((exp, index) =>
                                    {
                                        if(even < 2){
                                            if(even === 1) odd = 0;
                                            even++
                                            return (
                                                <div key={index} className="col-lg-6 col-md-12">
                                                    <div className="exprince_box ex_leftsidebox">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                                                                <div className="ex_leftside">
                                                                    <h1>{new Date(exp.experience_from).getFullYear()}</h1>
                                                                    <h4>{new Date(exp.experience_from).toLocaleString('default', { month: 'short' })} to {new Date(exp.experience_to).toLocaleString('default', { month: 'short' })}</h4>
                                                                    <h1>{new Date(exp.experience_to).getFullYear()}</h1>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                                                                <div className="ex_rightside">
                                                                    <h4>{exp.title} <span data-hover="Designer" className="data_hover c-pink">{exp.sub_title}</span></h4>
                                                                    <span className="c-pink">{exp.company_name}</span>
                                                                    <p className="ex_details">{exp.description}</p>
                                                                    <a className="ex_btn">Read More</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else if(odd < 2){
                                            if(odd === 1) even = 0;
                                            odd++
                                            return (
                                                <div key={index} className="col-lg-6 col-md-12">
                                                    <div className="exprince_box ex_rightsidebox">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 w-100">
                                                                <div className="ex_rightside">
                                                                    <h4>{exp.title} <span data-hover="Developer" className="data_hover c-orange">{exp.sub_title}</span></h4>
                                                                    <span className="c-orange">{exp.company_name}</span>
                                                                    <p className="ex_details">{exp.description}</p>
                                                                    <a className="ex_btn">Read More</a>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12 padding-0 w-100">
                                                                <div className="ex_leftside">
                                                                    <h1>{new Date(exp.experience_from).getFullYear()}</h1>
                                                                    <h4>{new Date(exp.experience_from).toLocaleString('default', { month: 'short' })} to {new Date(exp.experience_to).toLocaleString('default', { month: 'short' })}</h4>
                                                                    <h1>{new Date(exp.experience_to).getFullYear()}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                            
                                    }
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Experience;