import React, { Component } from 'react';
class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_responsor_setions prt_toppadder80 prt_bottompadder80 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client1.svg'} alt="" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client2.svg'} alt="" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client3.svg'} alt="" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client4.svg'} alt="" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client5.svg'} alt="" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="port_swipper_responsor">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/clients/client6.svg'} alt="" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Clients;