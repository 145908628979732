import React, { Component } from 'react';
import Firebase from '../common/FirebaseConfig';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ServiceList : []
        }
    }
    componentDidMount(){     
        const that = this;
        const servicesRef = Firebase.database().ref(this.props.uid + '/services');
        servicesRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let newState = [];
            for (let item in items) {
                newState.push({
                    id: item,
                    title: items[item].title,
                    icon: items[item].icon,
                    description: items[item].description,
                });
            }
            that.setState({
                ServiceList: newState
            });
        });
        
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_services_setions prt_toppadder80" style={ this.state.ServiceList.length === 0 ? { display : 'none' } : { display : 'block' } }>
                    <div className="services_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >Take a look</h2>
                                        </div>
                                        <h1 className="port_heading">My Services</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.ServiceList.map(service => 
                                    <div key={service.id} className="col-lg-4 col-md-6 text-center">
                                        <div className="port_services_box_wrapper">
                                            <div className="port_services_box">
                                                <img src={service.icon} alt="" />
                                                <h2 className="project_heading">{service.title}</h2>
                                                <p className="project_pera">{service.description}</p>
                                            </div>	
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Services;