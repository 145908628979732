import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class BlogList extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="port_blog_setions prt_toppadder80 prt_bottompadder50">
                    <div className="blog_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >My Blog</h2>
                                        </div>
                                        <h1 className="port_heading">Recent News</h1>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 col-lg-4">
                                    <div className="port_blog_mainbox vertical_content">
                                        <div className="port_blog_imgbox">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/port-blog1.jpg'} alt="" className="img-fluid"/>	
                                        </div>	
                                    
                                    
                                        <div className="port_blog_contentbox">
                                            <span className="date_span">January 28, 2019</span>
                                            <h4 className="blog_heading">
                                                <Link to="/blog-single">We are provide unique ideas we help clients.</Link>
                                            </h4>
                                            <div className="blog_info">
                                                <ul>
                                                    <li><i className="fas fa-user-edit"></i><a href="">By Admin</a> </li>
                                                    <li><i className="far fa-comments"></i><a href="">50</a></li>
                                                </ul>
                                            </div>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.It has roots in a piece of years old.Contrary to popular belief, Lorem Ipsum is not simply random text.It has roots in a piece of years old.</p>
                                            <div className="blog_readmore">
                                                <Link to="/blog-single" className="readmore_btn">Read More <i className="fas fa-long-arrow-alt-right"></i></Link>
                                            </div>
                                        </div>	
                                    </div>	
                                </div>
                                
                            
                                <div className="col-lg-8 col-md-12 ">
                                    <div className="port_blog_mainbox left_content">
                                        <div className="row no-gutters">
                                            <div className="col-md-6">
                                                <div className="port_blog_contentbox">
                                                    <span className="date_span">January 28, 2019</span>
                                                    <h4 className="blog_heading">
                                                        <Link to="/blog-single">See Our most successful complited project.</Link>
                                                    </h4>
                                                    <div className="blog_info">
                                                        <ul>
                                                            <li><i className="fas fa-user-edit"></i><a href="">By Admin</a> </li>
                                                            <li><i className="far fa-comments"></i><a href="">50</a></li>
                                                        </ul>
                                                    </div>
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text classical.</p>
                                                    <div className="blog_readmore">
                                                        <Link to="/blog-single" className="readmore_btn">Read More <i className="fas fa-long-arrow-alt-right"></i></Link>
                                                    
                                                    </div>
                                                </div>	
                                            </div>
                                            <div className="col-md-6 order-sm-first order-col-first align-self-center">
                                                <div className="port_blog_imgbox">
                                                    <img src={process.env.PUBLIC_URL + '/assets/images/port-blog2.jpg'} alt="" className="img-fluid"/>	
                                                </div>	
                                            </div>
                                        </div>
                                    </div>                            
                                
                                    <div className="port_blog_mainbox right_content">
                                        <div className="row no-gutters">
                                            <div className="col-md-6 align-self-center">
                                                <div className="port_blog_imgbox">
                                                    <img src={process.env.PUBLIC_URL + '/assets/images/port-blog3.jpg'} alt="" className="img-fluid"/>	
                                                </div>	
                                            </div>
                                            <div className="col-md-6">
                                                <div className="port_blog_contentbox">
                                                    <span className="date_span">January 28, 2019</span>
                                                    <h4 className="blog_heading">
                                                        <Link to="/blog-single">Unique ideas products are complited project.</Link>
                                                    </h4>
                                                    <div className="blog_info">
                                                        <ul>
                                                            <li><i className="fas fa-user-edit"></i><a href="">By Admin</a> </li>
                                                            <li><i className="far fa-comments"></i><a href="">50</a></li>
                                                        </ul>
                                                    </div>
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text of classical.</p>
                                                    <div className="blog_readmore">
                                                        <Link to="/blog-single" className="readmore_btn">Read More <i className="fas fa-long-arrow-alt-right"></i></Link>
                                                    </div>
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default BlogList;