import React, { Component } from 'react';
import Firebase from '../common/FirebaseConfig';
import {Redirect} from 'react-router-dom';

class Education extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect : '',
            EducationList : []
        }
    }
    componentDidMount(){
        const that = this;
        const refcheck = Firebase.database().ref(this.props.uid);
        refcheck.once('value', function(snapshot) {
            if (snapshot.hasChild('/')) {
                if(snapshot.key === that.props.uid){
                    const educationRef = Firebase.database().ref(that.props.uid + '/education');
                    educationRef.on('value', (snapshot) => {
                        let items = snapshot.val();
                        let newState = [];
                        for (let item in items) {
                            newState.push({
                                id: item,
                                title: items[item].title,
                                location: items[item].location,
                                year: items[item].year,
                                description: items[item].description,
                            });
                        }
                        that.setState({
                            EducationList: newState
                        });
                    });
                }
            }else{
                that.setState({redirect: true});
            }
        });   
    }
    render() { 
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        }
        return ( 
            <React.Fragment>
                <div className="port_education_setions prt_toppadder80 prt_bottompadder80" style={ this.state.EducationList.length === 0 ? { display : 'none' } : { display : 'block' } } >
                    <div className="education_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="port_heading_wrapper text-center prt_bottompadder40">
                                        <div className="port_sub_heading_wrapper">
                                            <h2 className="port_sub_heading" >Learning </h2>
                                        </div>
                                        <h1 className="port_heading">My Education</h1>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="education_main_wrapper ">
                                        
                                        {this.state.EducationList.map((edu, index) => 
                                            (index + 1) % 2 ?
                                            <div key={index} className="education_box education_firsrtbox firstbox">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                                                        <div className="education_mleft education_left ">
                                                            <div className="edu_mainyear edu_leftyear">
                                                                <h1>{edu.year}</h1>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-8 col-sm-12 col-12 align-self-center">
                                                        <div className="education_mright education_right ">
                                                            <div className="education_minfo education_rinfo ">
                                                                <div className="prt_rightside_title">
                                                                    <div className="left_title_box">
                                                                        <div className="left_title">
                                                                            <h4>0{index + 1}</h4>
                                                                        </div>
                                                                        <div className="right_title bg-orange">
                                                                            <h4>{edu.location}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h3 className="education_place">{edu.title}</h3>
                                                                <p>{edu.description}</p>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} className="education_box education_secondbox secondbox">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-8 col-sm-12 col-12 align-self-center">
                                                        <div className="education_mright education_left ">
                                                            <div className="education_minfo education_rinfo ">
                                                                <div className="prt_rightside_title">
                                                                    <div className="left_title_box">
                                                                        <div className="right_title bg-yellow">
                                                                            <h4>{edu.location}</h4>
                                                                        </div>
                                                                        <div className="left_title">
                                                                            <h4>0{index + 1}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h3 className="education_place">{edu.title}</h3>
                                                                <p>{edu.description}</p>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                                                        <div className="education_mleft education_left ">
                                                            <div className="edu_mainyear edu_leftyear">
                                                                <h1>{edu.year}</h1>
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Education;