import React, { Component } from 'react';

import Sidebar from '../common/Sidebar';
import {AddScript} from '../common/helpers.js';

class BlogSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount(){
        //add script
        this.jsfiles= [{ name : 'scrollbar.js' }, { name : 'custom.js' }]
        this.jsfiles = AddScript(this.jsfiles);
    }
    componentWillUnmount(){
        //remove script
        for(let jsfile of this.jsfiles){
            document.body.removeChild(jsfile.instance);
        }
    }
    render() { 
        return ( 
            <React.Fragment>
                <Sidebar />
                <div className="port_sec_warapper">
                    <div className="port_singleblog_wrapper prt_toppadder80 prt_bottompadder80 ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="blog_wrapper">
                                        <div className="blog_data">
                                            <div className="blog_thumb">
                                                <img src="assets/images/port_singleblog01.jpg" alt="" className="img-fluid" />
                                            </div>
                                            <div className="blog_content">
                                                <div className="blog_postinfo pb-3">
                                                    <ul>
                                                        <li><a href="#"> <i className="fas fa-calendar-alt"></i> january 21st, 2019</a></li>
                                                        <li><a href="#"> <i className="fas fa-comments"></i> 1 Comment</a></li>
                                                        <li><a href="#"> <i className="fas fa-user-edit"></i> by admin</a></li>
                                                    </ul>
                                                </div>
                                                <h4 className="blog_heading">We are provide unique ideas we help clients.</h4>
                                                <p className="pt-3 pb-3">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Syd College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsa passage, and going through the cites of the word in the undoubtable source.</p>
                                                
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                <blockquote>
                                                    <i className="fas fa-quote-left blockquote_icon"></i>There are many variations of passages of Lorem Ipsum available, but an majority have suffered alteration in some form, by injected humour, they randomised words which don't look even slightly believable.</blockquote>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                <div className="blog_shareinfo">
                                                    <div className="blog_tag">
                                                        <a href="#"> <i className="fas fa-tags"></i> education, Software, portfolio</a>
                                                    </div>
                                                    <div className="blog_social">
                                                        <ul className="social_list">
                                                            <li>
                                                                <a href="#" className="siderbar_icon">
                                                                    <span className="first_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                                                                    <span className="second_icon"><i className="fab fa-facebook-f nav_fb"></i></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="siderbar_icon">
                                                                    <span className="first_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                                                                    <span className="second_icon"><i className="fab fa-linkedin-in nav_in"></i></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="siderbar_icon">
                                                                    <span className="first_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                                                                    <span className="second_icon"><i className="fab fa-whatsapp nav_whats"></i></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="siderbar_icon">
                                                                    <span className="first_icon"><i className="fab fa-twitter nav_twit"></i></span>
                                                                    <span className="second_icon"><i className="fab fa-twitter nav_twit"></i></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="siderbar_icon">
                                                                    <span className="first_icon"><i className="fab fa-instagram nav_insta"></i></span>
                                                                    <span className="second_icon"><i className="fab fa-instagram nav_insta"></i></span>
                                                                </a>
                                                            </li>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget blog_comment">
                                            <div className="singlepost_title">
                                                <h4 className="widget_title">Comments (3)</h4>
                                            </div>
                                            <ul>
                                                <li className="latest_comment">
                                                    <div className="comment_thumb">
                                                        <img src="assets/images/singleblog_user01.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="comment_data">
                                                        <div className="client_name">
                                                            <h5>charli Johns</h5>
                                                            <span> January 21st, 2019</span>
                                                        </div>
                                                        <p>Lorem Ipsum has been the standard dummy text ever since the when an unknown printer took a galley of type and it to make a type book.</p>
                                                        <a href="#" className="comment_reply"><i className="fas fa-reply"></i> reply</a>
                                                    </div>
                                                </li>
                                                <li className="latest_comment sub_replay">
                                                    <div className="comment_thumb">
                                                        <img src="assets/images/singleblog_user02.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="comment_data">
                                                        <div className="client_name">
                                                            <h5>Taylor Johns</h5>
                                                            <span> January 21st, 2019</span>
                                                        </div>
                                                        <p>Lorem Ipsum has been the standard dummy text ever since the when an unknown printer took a galley of type and it to make a type book.</p>
                                                        <a href="#" className="comment_reply"><i className="fas fa-reply"></i> reply</a>
                                                    </div>
                                                </li>
                                                <li className="latest_comment">
                                                    <div className="comment_thumb">
                                                        <img src="assets/images/singleblog_user03.png" alt="" className="img-fluid"/>
                                                    </div>
                                                    <div className="comment_data">
                                                        <div className="client_name">
                                                            <h5>Walter Mark</h5>
                                                            <span> January 22st, 2019</span>
                                                        </div>
                                                        <p>Lorem Ipsum has been the standard dummy text ever since the when an unknown printer took a galley of type and it to make a type book.</p>
                                                        <a href="#" className="comment_reply"><i className="fas fa-reply"></i> reply</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="widget blog_reply">
                                            <div className="singlepost_title">
                                                <h4 className="widget_title">post a comment</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="port_contact_form">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <input type="text" name="first_name" className="form-control require" id="name" placeholder="First name" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <input type="text" name="last_name" className="form-control require" id="last-name" placeholder="Last name" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <input type="email" name="email" className="form-control require" id="email" placeholder="Email Address" data-valid="email" data-error="Email should be valid."/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <input type="text" name="subject" className="form-control require" id="subject" placeholder="Subject"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <textarea  name="message" className="form-control require" rows="3" id="comment" placeholder="Message"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="banner_btn">
                                                                        <button type="button" className="portfolio_btn btn_red">
                                                                            <span className="first_text">Submit</span>
                                                                            <span className="second_text">Send</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="response"></div>
                                                            </div>
                                                        </form>
                                                    </div>	
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="blogsidebar_wrapper">
                                        <div className="widget search_widget">
                                            <form>
                                                <input className="form-control" type="text" required="" name="search" placeholder="Search here..." />
                                                <a href="#" className="blog_searchicon"><i className="fas fa-search"></i></a>
                                            </form>									
                                        </div>
                                        <div className="widget repost_widget">
                                            <h4 className="widget_title">Recent Post</h4>	
                                            <div className="widget_rp">
                                                <ul>
                                                    <li>
                                                        <div className="rp_thumb"><a href="#"><img src="assets/images/blog_insta01.jpg" alt="" className="img-fluid"/></a></div>
                                                        <div className="rp_data">
                                                        <a href="#" className="rp_heading">Unique ideas products are complited project.</a>
                                                        <div><a href="#" className="rp_date">january 21st, 2019</a></div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="rp_thumb"><a href="#"><img src="assets/images/blog_insta02.jpg" alt="" className="img-fluid"/></a></div>
                                                        <div className="rp_data">
                                                        <a href="#" className="rp_heading">Contrary to is not simply random text.</a>
                                                        <div><a href="#" className="rp_date">January 21st, 2019</a></div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="rp_thumb"><a href="#"><img src="assets/images/blog_insta03.jpg" alt="" className="img-fluid"/></a></div>
                                                        <div className="rp_data">
                                                        <a href="#" className="rp_heading">it has a more-or-less normal of letters.</a>
                                                        <div><a href="#" className="rp_date">January 21st, 2019</a></div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="rp_thumb"><a href="#"><img src="assets/images/blog_insta04.jpg" alt="" className="img-fluid"/></a></div>
                                                        <div className="rp_data">
                                                        <a href="#" className="rp_heading">Unique ideas products are complited project.</a>
                                                        <div><a href="#" className="rp_date">January 21st, 2019</a></div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="rp_thumb"><a href="#"><img src="assets/images/blog_insta05.jpg" alt="" className="img-fluid"/></a></div>
                                                        <div className="rp_data">
                                                        <a href="#" className="rp_heading">The standard chunk of used since the 1500s.</a>
                                                        <div><a href="#" className="rp_date">January 21st, 2019</a></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget categroies_widget">
                                            <h4 className="widget_title">Categories</h4>
                                            <ul>
                                                <li><a href="#">Education <span className="category_num">(20)</span></a></li>
                                                <li><a href="#">Designer <span className="category_num">(50)</span></a></li>
                                                <li><a href="#">Projects <span className="category_num">(20)</span></a></li>
                                                <li><a href="#">Developer <span className="category_num">(70)</span></a></li>
                                                <li><a href="#">Clients <span className="category_num">(90)</span></a></li>
                                                <li><a href="#">Creativity <span className="category_num">(100)</span></a></li>
                                                <li><a href="#">Education <span className="category_num">(200)</span></a></li>
                                                <li><a href="#"> Angular <span className="category_num">(80)</span></a></li>
                                            </ul>
                                        </div>
                                        <div className="widget tag_widget">
                                            <h4 className="widget_title">Tag</h4>
                                            <ul>
                                                <li><a href="#" className="comment_reply">Education</a></li>
                                                <li><a href="#" className="comment_reply">Experience</a></li>
                                                <li><a href="#" className="comment_reply">Years </a></li>
                                                <li><a href="#" className="comment_reply">Angular</a></li>
                                                <li><a href="#" className="comment_reply">Creativity</a></li>
                                                <li><a href="#" className="comment_reply">Developer</a></li>
                                                <li><a href="#" className="comment_reply">Industry</a></li>
                                                <li><a href="#" className="comment_reply">Education</a></li>
                                                <li><a href="#" className="comment_reply">Freelance  </a></li>
                                            </ul>									
                                        </div>
                                        <div className="widget insta_widget">
                                            <h4 className="widget_title">instagram</h4>
                                            <ul>
                                                <li><a href="#"><img src="assets/images/blog_insta01.jpg" alt="" className="img-fluid"/></a></li>
                                                <li><a href="#"><img src="assets/images/blog_insta02.jpg" alt="" className="img-fluid"/></a></li>
                                                <li><a href="#"><img src="assets/images/blog_insta03.jpg" alt="" className="img-fluid"/></a></li>
                                                <li><a href="#"><img src="assets/images/blog_insta04.jpg" alt="" className="img-fluid"/></a></li>
                                                <li><a href="#"><img src="assets/images/blog_insta05.jpg" alt="" className="img-fluid"/></a></li>
                                                <li><a href="#"><img src="assets/images/blog_insta06.jpg" alt="" className="img-fluid"/></a></li>
                                            </ul>									
                                        </div>
                                    </div>
                                </div>	
                            </div>	
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default BlogSingle;